import React, {useState,useContext} from 'react';
import {useCallback, useEffect} from "react";
import {Loader} from "./Loader";
import {useMessage} from "../hooks/message.hook";
import {AuthContext} from "../context/auth.context";
import {usePayment} from "../hooks/payment.hook";
import {CurrentSubscriptionHeader} from "./CurrentSubscriptionHeader";
import {usePromoCodes} from "../hooks/promoCodes.hook";
import {useUsers} from "../hooks/user.hook";


export const NewPaymentComponent = () =>{

    const [currentSubscription,setCurrentSubscription] = useState({});
    const [promoCodeForm,setPromoCodeForm] = useState({
        name:"",
        result:null,
        isLoading:false
    });

    const [newPaymentForm, setNewPaymentForm] = useState({
        amount:1
    });

    const [newPaymentRequests, setNewPaymentRequests] = useState({
        isPaymentURLRequested:false,
        isPaymentURLReceived:false,
        isPaymentURLFailed:false,
        PaymentURL:"",
    })

    const [childUsers, setChildUsers] = useState([]);
    const [userTobePaid, setUserTobePaid] = useState({});

    const {getMonthlyPrice, GeneratePaymentLink, GetPaymentLink,GetCurrentSubscription, GetChildUserSubscriptions} = usePayment();
    const {getCurrentUser} = useUsers();
    const {searchPromoCode,usePromo} = usePromoCodes();

    const message = useMessage();



    const [monthlyPrice,setMonthlyPrice] = useState(-1);

    const {userType,userId,userName,userEmail} = useContext(AuthContext);

    const fetchMonthlyPrice = async ()=>
    {
        setMonthlyPrice(await getMonthlyPrice());
    }

    const payNowBtnClickHandler = async (user,paymentType)=>
    {
        setNewPaymentRequests({...newPaymentRequests,isPaymentURLRequested:true,isPaymentURLReceived:false,PaymentURL:""});

        const paymentOrder = await GeneratePaymentLink(user,paymentType,newPaymentForm);

        if(paymentOrder && paymentOrder.orderId)
        {
            await RequestPaymentURL(paymentOrder);
        }
    }

    const RequestPaymentURL = async (paymentOrder)=>{
        let data = await GetPaymentLink(paymentOrder.orderId);

        if(data.isLinkGenerated)
        {
            PaymentURLReceived(data.paymentURL,data.userAndPayerMatch);
        }
        else if(!data.isLinkGenerated && !data.isLinkGenerationFailed)
        {
            await RequestPaymentURL(paymentOrder);
        }
        else if(data.isLinkGenerationFailed)
        {
            PaymentURLFailed();
        }
    }

    const PaymentURLReceived = (paymentURL,userAndPayerMatch)=>
    {
        setNewPaymentRequests({...newPaymentRequests,
            isPaymentURLRequested:false,
            isPaymentURLFailed:false,
            isPaymentURLReceived:true,
            PaymentURL:paymentURL
        });

        window.location.assign(paymentURL);
    }

    const PaymentURLFailed = ()=>
    {
        setNewPaymentRequests({...newPaymentRequests,
            isPaymentURLFailed:true,
            isPaymentURLReceived:true
        });
    }

    const fetchCurrentSubscription = async()=>{
        setCurrentSubscription(await GetCurrentSubscription());
    }

    const fetchUsers = async ()=>{
       setChildUsers(await GetChildUserSubscriptions(null,false));
    }

    const pageLoaded = async ()=>{
        window.M.AutoInit();
        window.M.updateTextFields();

        fetchMonthlyPrice();
        fetchCurrentSubscription();

       if(userType === "manager")
           await fetchUsers();

    }

    useEffect(()=>{
        pageLoaded();
    },[]);

    const OnNewPaymentFormAmountChangeHandler = (newVal) =>{
        if(newVal <= 0)
        {
            newVal = 1;
        }

        setNewPaymentForm({...newPaymentForm,amount: newVal})
    }

    const printCurrentSubscriptionStatus = useCallback(()=>
    {
        let isSubscriptionActive = currentSubscription.status.isSubscriptionActive;

        return(
            <>
                <CurrentSubscriptionHeader showbtn={false} forceShow={true}/>
                <h6>Profile Status:
                    <span className="profile-status" style={{backgroundColor:(isSubscriptionActive?"#40d240":"#e66969")}}>
                        {isSubscriptionActive?(
                            "Active until "+ new Date(currentSubscription.status.subscriptionEndDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }))
                            :"Payment expired"}
                    </span>
                </h6>
            </>
        )
    },[currentSubscription])

    const printChildCurrentSubscriptionStatus = (childSubscription)=>{

        if(!childSubscription.status) return null;

        let isSubscriptionActive = childSubscription.status.isSubscriptionActive;

        return(
            <>
                <h6>Profile Status:
                    <span className="profile-status" style={{backgroundColor:(isSubscriptionActive?"#40d240":"#e66969")}}>
                        {isSubscriptionActive?(
                                "Active until "+ new Date(childSubscription.status.subscriptionEndDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }))
                            :"Payment expired"}
                    </span>
                </h6>
            </>
        )
    }


    const delimitNum = (num) =>
    {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const printNewPaymentSection = (user,paymentForChildUser)=>{

        return (
            <>
                <h5>New Payment {paymentForChildUser?"for Agent":""}</h5>
                <div className="divider"></div>
                {Object.keys(currentSubscription).length > 0 && monthlyPrice !== -1?
                    <>
                        <p style={{marginTop:"20px"}}> Name: <span>{user.name}</span></p>
                        <p> Email: <span>{user.email}</span></p>
                        <br/>
                        <p> 1 month =  {delimitNum(monthlyPrice)} AMD</p>
                        <p> Buy Months:
                            <div className="new-payment-months-container">
                                <input  value={newPaymentForm.amount} onChange={(e)=>{OnNewPaymentFormAmountChangeHandler(e.target.value)}}/>
                                <div className="arrows-container">
                                    <button className="waves-effect waves-light btn arrow-up" onClick={(e)=>{OnNewPaymentFormAmountChangeHandler(newPaymentForm.amount+1)}}><i className="material-icons">arrow_drop_up</i></button>
                                    <button className="waves-effect waves-light btn arrow-down" onClick={(e)=>{OnNewPaymentFormAmountChangeHandler(newPaymentForm.amount-1)}}><i className="material-icons">arrow_drop_down</i></button>
                                </div>
                            </div>
                        </p>
                        <p className="total"> Total: <span>{delimitNum(newPaymentForm.amount * monthlyPrice)} AMD</span></p>

                        <div>
                            {printPaymentButton(user)}
                        </div>

                    </>:<Loader/>
                }

            </>
        )
    };


    const printPaymentButton =(user)=>{

        if(newPaymentRequests.isPaymentURLRequested)
        {
            return (<Loader/>)
        }
        else if(newPaymentRequests.isPaymentURLReceived && !newPaymentRequests.isPaymentURLFailed)
        {
            return (<span className="redirecting-message">Redirecting</span>);
        }
        else if(newPaymentRequests.isPaymentURLReceived && newPaymentRequests.isPaymentURLFailed)
        {
            return (<span className="redirecting-failed">Payment Failed, please try again</span>);
        }
        else if(!newPaymentRequests.isPaymentURLRequested)
        {
            return (
                <>
                    <br/>
                    <br/>
                    <button className="btn waves-effect waves-light green" type="submit" name="action"
                            onClick={()=>payNowBtnClickHandler(user,"Idram")}>Idram
                        <i className="material-icons right">payment</i>
                    </button>
                    <br/>
                    <br/>
                    <span style={{
                        color:"red",
                        fontSize: "small"
                    }}>Visa Payment Temporary not supported. Use "iDRAM"</span>
                    <button className="btn waves-effect waves-light green" type="submit" name="action" disabled={true}
                            onClick={()=>payNowBtnClickHandler(user,"Arca")}>Visa/Master card
                        <i className="material-icons right">payment</i>
                    </button>
                </>

            )
        }
    }

    const printPromoCodeContainer = ()=>{

        return (
            <div className="row promocode-container">
                <div className="col s12">
                    <div className="row">
                        <div className="col s6">
                            <div className="input-field">
                                <input id="promoCode" autoComplete="off" type="text" value={promoCodeForm.name} onChange={(e)=>{setPromoCodeForm({...promoCodeForm,name:e.target.value})}}  />
                                <label htmlFor="promoCode">Promo Code</label>
                            </div>
                        </div>
                        <div className="col s6">
                            <button className="btn orange" style={{marginLeft:"-20px", marginTop:"26px"}} onClick={SearchPromocodeHandler}><i className="material-icons">search</i></button>
                        </div>
                    </div>
                </div>
                {
                    (promoCodeForm.result && promoCodeForm.result.promo &&  promoCodeForm.result.promo.name)? (
                        <>
                            <div className="col s12 promocode-result">
                                <h6>Available Promo Code</h6>
                                <span>{promoCodeForm.result.promo.effectType} {promoCodeForm.result.promo.effectValue}</span>
                                <br/>
                                <button className="btn green" style={{marginTop:"26px"}} onClick={UsePromocodeHandler}>Use Promo Code</button>

                            </div>
                        </>
                    ):(promoCodeForm.isLoading? <Loader />
                        :(promoCodeForm.result && promoCodeForm.result.message)? (<span>
                                {promoCodeForm.result.message}
                            </span>):null)
                }

            </div>
        )
    }

    const SearchPromocodeHandler =async ()=>{
        setPromoCodeForm({...promoCodeForm,isLoading: true});

        const result = await searchPromoCode(promoCodeForm.name);
        if(result.promo && result.promo.name)
        {
            setPromoCodeForm({...promoCodeForm,result: result, isLoading: false});
        }
        else
        {
            setPromoCodeForm({...promoCodeForm,result: {message:result}, isLoading: false});
        }
    }

    const UsePromocodeHandler = async ()=>{
        setPromoCodeForm({...promoCodeForm,isLoading: true});

        const result = await usePromo(promoCodeForm.result.promo.name);
        window.location.reload(false);
        setPromoCodeForm({...promoCodeForm,isLoading: false,result:null, name:""});
    }


    const PayforTeamOpenPopupHandler = (user)=>{

        setUserTobePaid(user)

        let modal = window.document.getElementById('payforteam_modal');
        window.M.Modal.init(modal,{});
        let instance = window.M.Modal.getInstance(modal);
        instance.open();
    }

    const PrintMyPaymentSection = ()=>{
        return (
            <div className="section">

                <div className="row payment-details">
                    <div className="col s12 m6">
                        <div className="row">
                            <div className="col s12">
                                <h5>Current Subscription</h5>
                                <div className="divider"></div>
                                {Object.keys(currentSubscription).length > 0?
                                    <>
                                        {printCurrentSubscriptionStatus()}
                                    </>
                                    :<Loader/>
                                }
                            </div>
                            <div className="col s12">
                                {printPromoCodeContainer()}
                            </div>
                        </div>

                    </div>

                    <div className="col s12 m6">
                        {printNewPaymentSection(currentSubscription,false)}
                    </div>
                </div>


            </div>
        )
    }

    const printTeamPaymentsList = ()=>{
        return (
            <table>
                <thead>
                    <tr>
                        <td>Name</td>
                        <td>Email</td>
                        <td>End Date</td>
                        <td>Days Left</td>
                    </tr>
                </thead>
                <tbody>
                {childUsers.map(function(childUser, i){

                    let color = "";
                    if(childUser.status.isSubscriptionActive)
                    {
                        if(childUser.status.subscriptionDaysLeft <= 7)
                        {
                            color = "#e7912b";
                        }
                        else
                        {
                            color = "#40d240";
                        }
                    }
                    else
                    {
                        color = "#e66969";
                    }

                    return (
                        <tr>
                            <td>{childUser.name}</td>
                            <td>{childUser.email}</td>
                            <td>

                                <span className="profile-status" style={{backgroundColor:color}}>
                                    {childUser.status.isSubscriptionActive?(
                                         new Date(childUser.status.subscriptionEndDate).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' }))
                                        :"Payment expired"}
                                </span>
                            </td>
                            <td>
                                {childUser.status.isSubscriptionActive?(
                                    childUser.status.subscriptionDaysLeft
                                ):""}
                            </td>
                            <td>
                                {childUser.isEmailVerified? (
                                    <button className="btn waves-effect waves-light green" type="submit" name="action"
                                            onClick={() => PayforTeamOpenPopupHandler(childUser)}>Pay
                                        <i className="material-icons right">payment</i>
                                    </button>

                                ):"Email is not verified"}

                            </td>
                        </tr>
                    );
                })}
                </tbody>

            </table>
        );
    }


    return(
        <>
            <br/>
            <div className="divider"></div>
            {userType === "manager"? (
                <div className="row">
                    <div className="col s12">
                        <ul className="tabs">
                            <li className="tab col s3"><a  className="active"  href="#payForMe">Pay for Me</a></li>
                            <li className="tab col s3"><a href="#payForTeam">Pay for Team</a></li>
                        </ul>
                    </div>
                    <div id="payForMe" className="col s12">
                        {PrintMyPaymentSection()}
                    </div>
                    <div id="payForTeam" className="col s12">
                        {printTeamPaymentsList()}
                    </div>
                </div>
            ):(
                PrintMyPaymentSection()
            )}


            <div className="divider"></div>


            <div id="payforteam_modal" className="modal">
                <div className="modal-content">
                    <div className="row payment-details">
                        <div className="col s12 m6">
                            <div className="row">
                                <div className="col s12">
                                    <h5>Current Subscription</h5>
                                    <div className="divider"></div>
                                    {Object.keys(currentSubscription).length > 0?
                                        <>
                                            {printChildCurrentSubscriptionStatus(userTobePaid)}
                                        </>
                                        :<Loader/>
                                    }
                                </div>
                            </div>

                        </div>

                        <div className="col s12 m6">
                            {printNewPaymentSection(userTobePaid,true)}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <div className="row">
                        <button className="btn grey modal-close"  style={{marginLeft:10}}>Cancel</button>
                    </div>
                </div>
            </div>
        </>
    );
}